const initialState = {
  spacerHeight: null,
  spacerInit: false,
  gridThresh: 100,
  insta: false,
  toggle: false,
  arrowAngle: 0
}

export default function utilReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SPACER":
      return {
        ...state,
        spacerHeight: action.payload
      }

    case "SET_SPACER_INIT":
      return {
        ...state,
        spacerInit: action.payload
      }
    case "SET_GRIDTHRESH":
      return {
        ...state,
        gridThresh: action.payload
      }
    case "SET_TOGGLE":
      return {
        ...state,
        toggle: action.payload
      }
    case "SET_ARROW_ANGLE":
      return {
        ...state,
        arrowAngle: action.payload
      }
    case "SET_INSTA":
      return {
        ...state,
        insta: action.payload
      }
    default: return state
  }
}