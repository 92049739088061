import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector  } from 'react-redux';

const CoverEl = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  opacity: ${props => props.loaded ? 0 : 1};
  transition: opacity .2s ease;
  pointer-events: none;
`
const Cover = () => {
  const [ Loaded, setLoaded ] = useState(false);
  const utilRedux = useSelector(state => state.utilReducer);

  useEffect(() => { 
    if(utilRedux.spacerInit && !Loaded) {
      setTimeout(function() {
        window.scrollTo(0, utilRedux.spacerInit);
        setLoaded(true);
      }, 1000)
    }
  }, [utilRedux.spacerInit]);

  return (
    <>
      <CoverEl loaded={Loaded}/>
    </>
  )
}

export default Cover;