import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import combineReducers from './src/reducers/index';

const rootReducer = (state, action) => {
  return combineReducers(state, action);
}

export default function configuredStore() {
  const middlewares = [thunk];
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  return store;
}