module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"myrch-club","accessToken":"MC5ZTDNoN2hJQUFDSUE4ZFFa.V--_ve-_ve-_ve-_ve-_vWBELFnvv71N77-977-9Xe-_vX_vv71g77-977-977-977-977-977-9BDIzCynvv73vv70","schemas":{"filter":{"Main":{"tags":{"type":"Group","config":{"fields":{"tag_name":{"type":"Text","config":{"label":"Tag Name"}},"logo":{"type":"Color","config":{"label":"Logo"}},"background":{"type":"Color","config":{"label":"Background"}},"tag_text_active":{"type":"Color","config":{"label":"Tag Text Active"}},"text_colour":{"type":"Color","config":{"label":"Text Colour"}}},"label":"Tags"}}}},"myrch_block":{"Main":{"uid":{"type":"UID","config":{"label":"UID"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"image_hover":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image Hover"}},"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Logo"}},"product_text":{"type":"Text","config":{"label":"Product Text"}},"category_text":{"type":"Text","config":{"label":"Category Text"}},"url":{"type":"Link","config":{"allowTargetBlank":true,"label":"Url"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TBZTZ7Q","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DEHWFDLG8N"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
