const initialState = {
  selectedTag: null
}

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT_TAG":
      return {
        ...state,
        selectedTag: action.payload
      }
    default: return state
  }
}